package dev.jmadaminov.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    dev.jmadaminov.components.layouts.PageLayout("CV") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("madaminov-jakhongir-senior-se-uzum") }) {
            org.jetbrains.compose.web.dom.Text("Madaminov Jakhongir | Senior SE @ Uzum")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("I am a passionate software engineer with a deep focus on bringing high-quality products to end users. ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("10 years")
            }
            org.jetbrains.compose.web.dom.Text(" of programming ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("XP")
            }
            org.jetbrains.compose.web.dom.Text(", performing as an ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Android developer")
            }
            org.jetbrains.compose.web.dom.Text(" at core + some ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Unity3D game development")
            }
            org.jetbrains.compose.web.dom.Text(" journey which lasted only couple years. An open-minded, responsive team player with remarkable troubleshooting and problem-solving skills. I always strive to keep up with new trends, constantly developing my skills.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Achievements:")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("✅  Authored couple super interesting Medium articles which got promoted to “Better Programming” channel.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("✅  Multiple speeches on GDG dev-fest and mobile meet-ups.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("✅  Published a Deeplink framework and a modern UI development library which received *Many stars badge.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("✅  Lead a startup to a failure 😛 with a team of 4 developers and designed/developed a personal start-up project based on a ride-sharing application.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("✅  Developed and published a non-profit app which gained more than 500 000 downloads.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Madaminov Jakhongir | SENIOR SOFTWARE ENGINEER")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("EPAM")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("CORE COMPETENCIES")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Team Leading")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Agile software development CI/CD")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Design Patterns")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("PROFESSIONAL EXPERIENCE")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Senior Software Engineer | Nov, 2021 - Apr, 2023- Canadian Tire")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Senior Software Engineer | May, 2021 - Nov, 2021 - MX51")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Assisted in the delivery of new features and bug-fixes while smashing through Jira tickets. Comprehensive collaboration in migrating the existing app from fragments to full jetpack compose based app. Tackled a lot of challenges during the migration, like cloning and tweaking the existing library to fit the feature requirements and interoperability with jetpack compose. More complex migrations has been carried out by me independently which included getting rid of LiveData for the benefits of flows, groovy build scripts to KTS, and KAPT annotation processors to KSP. Worked with different build types and flavors. And other huge amount of work related to UI composables.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Team size: Android Team - 4, PM - 1, QA - 3, UI/UX Designer - 1")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Tech: Jira, Github-actions, SonarQube, Jetpack Compose, Kotlin, Coroutines, Flow, Ktor.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Axon Logic")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Android Team Lead | Apr, 2019 - May, 2021")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Project - Uzrailway tickets - Android App for purchasing railway tickets online")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Role: Developed the whole app (Architecture/UI/Domain logic/Api integration/Testing). Participated in the development of Backend API (Java Spring Boot). Took part in the development of Admin front end (Tymeleaf framework). Divided several backend project modules into micro-services.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Team size: Android Team - 3-4, PM - 1, QA - 1, UI/UX Designer - 1")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Tech (Android): PostgreSQL, Firebase Firestore/Auth/CM, RxJava, Rertofit2, Leak Canary, Hilt")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Tech (Backend): Java/Kotlin, PgAdmin, Thymeleaf, Spring boot, Axon framework, Gitlab, Redmine, Jenkins")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Any mobile/Sab Games")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Android Developer, Unity3d Developer | May, 2015 - Feb, 2019")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Projects (Android): \"Kamera Bor\" anti road-cam app, \"Berol\" E-Commerce app, \"40Uz\" -")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Online car trading app, \"Dom-Vet\" veterinary service")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Projects (Unity3d): Candy Crush clone, multiple casual games...")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Role: It's like having a double personality, developed games and apps at the same time)). Team size: Android Team - 1, Unity3d Team - 3-4, PM - 1, QA - 1, UI/UX Designer - 1")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Sunet Technology LLC")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Android Developer | Aug, 2014 - Feb, 2015")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Worked on an application development for pharmaceutical company integrating features like")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("offline PDF Viewer with downloadable content and recording geo location of employees with GPS tracking and keeping records in database and presenting in front-end website using Google Maps Web API")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Datasite Technologies")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Junior Android Developer | May, 2014 - Aug, 2014")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.Text("Built a simple application with static data and basic user interactions for a trial period. Worked")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("under coordination of a senior Android developer in integrating different features to a shopping application. Autonomously, developed an experimental application for Muslim prayers that automatically puts smartphone on silent mode as they enter Mosque building by performing polygonal area calculations with user’s geo location.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("OTHER")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("English: C1 Russian: Fluent Japanese: Intermediate Uzbek: Native Certified Unity3d developer - Passed Unity3D certification")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("EDUCATION")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("2010 - 2014 | Westminster International University")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("BSc - Business Information Systems")
        }
    }
}
