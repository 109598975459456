package dev.jmadaminov.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.silk.components.graphics.Image
import dev.jmadaminov.components.layouts.PageLayout
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun HomePage() {

    PageLayout("Jahongir Madaminov") {
        Box(modifier = Modifier.fillMaxWidth()) {
            Image("me.png", modifier = Modifier.align(Alignment.Center).width(400.px))
        }

        Row(modifier = Modifier.padding(50.px)) {
            Anchor("https://linkedin.com/in/jakhongir-madaminov") {
                Image("ic_linkedin.png", modifier = Modifier.size(50.px))
            }

            Box(modifier = Modifier.size(50.px))
            Anchor("https://github.com/jakhongirmadaminov") {
                Image("ic_github.png", modifier = Modifier.size(50.px))
            }
        }


        Text("jahongir9779@gmail.com")


//        Text("Developer page")
//        var name by remember { mutableStateOf("") }
//        TextInput(name, onTextChanged = { name = it }, Modifier.margin(top = 0.5.cssRem))
//        P()
//        Text("Hello ${name.takeIf { it.isNotBlank() } ?: "World"}!")
    }
}
