package styles

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.filter
import org.jetbrains.compose.web.css.percent

@OptIn(ExperimentalComposeWebApi::class)
val SocialButtonStyle by ComponentStyle {
    base {
        Modifier
            .color(Color.white)
            .styleModifier {
                filter {

//                    grayscale(100.percent)
//                    color(Color.white)
                }
            }
//            .transition(CSSTransition(property = "filter", duration = 200.ms))
    }
//    hover {
//        Modifier.styleModifier {
//            filter { grayscale(0.percent) }
//        }
//    }
}
